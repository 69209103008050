<template>
  <popupCharge :activation="charge" v-if="charge"/>
  <caisse :activation="activeCaisse" v-if="activeCaisse" @oga="retourCaisse"/>
  <div class="p-10 pr-16 all">
    <div class="mt-32 mb-20">
      <div
        class="flex items-center"
      >
        <div class="w-4/5 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4 w-3/5">
            Caisses
          </div>
        </div>

        <div class="w-1/5">
          <bouton label="Créer une caisse" height="54px" class="w-full" @info="activeCaisse = true"/>
        </div>


      </div>

      <div class="mt-6 pl-4" v-if="allData !== null">
        <bloc :donnes="allData.agencies" />

        <div class="mt-12">
          <lie :donnes="allData.persons" />
        </div>

        <div class="mt-12">
          <bloc :donnes="allData.departments" type="Caisses par département" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bloc from '../../component/cdevs/caisse/defaultBloc'
import lie from '../../component/cdevs/caisse/associateBloc'
import bouton from '../../component/helper/add/button'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'
import caisse from '../../component/popup/newCaisse'

export default {
  name: "Index",

  components: {
    bloc,
    lie,
    popupCharge,
    bouton,
    caisse
  },

  data () {
    return {
      icons: {
        back,
      },
      allData: null,
      charge: true,
      activeCaisse: false
    }
  },

  created () {
    this.allWallet()
  },

  methods: {
    allWallet () {
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.wallet)
          .then(response => {
            console.log(response)
            this.allData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourCaisse (answer) {
      if (answer) {
        this.allWallet()
      }
      this.activeCaisse = false
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}
</style>
