<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Ajouter une caisse
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="text-left text-c12 mt-4">
              Département
            </div>
            <multiselect
              class="mt-2"
              value-t="Comptabilité"
              :option="optionDepartement"
              @info="retourDepartement"
            />

            <div v-if="optionResponsable.length > 0">
              <div class="text-left text-c12 mt-8">
                Responsable
              </div>
              <multiselect
                  class="mt-2"
                  :option="optionResponsable"
                  @info="retourResponsable"
              />
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import multiselect from '../helper/form/multiselect'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"

export default {
  name: 'Success',
  components: { PopupBase, buton, multiselect },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      error: null,
      charge: false,
      optionDepartement: ['Comptabilité', 'Technique'],
      departement: 'ACCOUNTING',
      allAgent: [],
      optionResponsable: [],
      respo: null
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.allUser()
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    continuer () {
      this.error = null
      if (this.departement.length > 0 && this.respo.length > 0) {
        this.charge = true
        let body = {
          department: this.departement,
        }
        for (let item in this.allAgent) {
          if (this.allAgent[item].person.fullname === this.respo) {
            body.responsible = this.allAgent[item].person.id
          }
        }
        this.save(body)
      } else {
        this.charge = false
        this.error = 'Veuillez selectionner des valeurs pour continuer'
      }
    },

    save (body) {
      http.post(apiroutes.baseURL + apiroutes.createWallet, body)
          .then(response => {
            console.log(response)
            this.$emit('oga', true)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourDepartement (answer) {
      if (answer !== null && answer !== 'Autres'){
        this.optionResponsable = []
        if (answer === 'Comptabilité'){
          this.departement = 'ACCOUNTING'
        }
        if (answer === 'Technique'){
          this.departement = 'TECHNICIAN'
        }
        this.allUser()
      }

    },

    retourResponsable (answer) {
      this.respo = answer
    },

    allUser () {
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.allUser + '?type=' + this.departement)
          .then(response => {
            console.log(response)
            this.allAgent = response
            for (let item in response) {
              this.optionResponsable.push(response[item].person.fullname)
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Impossible de charger les utilisateurs de ce département"
            if (error.status === 500) {
              this.error = "Conflit un responsable est déjà atribuer pour ce département"
            }
          })
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .add{
      background-color: #ECECEC;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
