<template>
  <div class="text-left">
    <div>
      <div class="text-90 text-c18">{{ type }}</div>
      <div class="h-1 bg-d w-full"> </div>
    </div>

    <div class="flex flex-wrap">
      <div class="w-1/3 pr-4" v-for="(item, index) in donnes" :key="index">
        <div class="item bg-white cursor-pointer p-6 mt-6">
          <div class="w-full flex text-c14 font-c4 text-left" @click="goToDetail(item)">
            <div class="w-3/4 flex items-center">
              <icon
                  :data="icons.caisse"
                  height="35"
                  width="35"
                  class="mr-2 cursor-pointer"
                  original
              />
              <div class="text-c16 w-full">
                <span v-if="item.agency">{{ item.agency.label }}</span>
                <span v-if="item.department">{{ item.department }}</span>
              </div>
            </div>

            <div class="w-1/4 text-c12 text-60">
              <span class="text-green-clear font-c7">+2% </span>cette semaine
            </div>
          </div>

          <div class="mt-4" @click="goToDetail(item)">
            <div class="text-black text-c22 font-c5">
              {{ refactorAmount(item.amount, '.') }}
              <span class="text-90">fcfa</span>
            </div>
          </div>

          <div class="flex mt-4 items-center">
            <div class="w-1/2" @click="goToDetail(item)">
              <div class="text-c8 text-60">Responsable actuel :</div>
              <div class="flex items-center mt-2">
                <namer
                    first="Eric"
                    second="Amour"
                    height="23px"
                    width="23px"
                    size="9px"
                    v-if="!item.responsible"
                />
                <namer
                    :first="item.responsible.fullname"
                    :second="item.responsible.fullname.substr(1, item.responsible.fullname.length)"
                    height="23px"
                    width="23px"
                    size="9px"
                    v-if="item.responsible"
                />
                <div class="ml-2 text-c10">
                  <span v-if="!item.responsible">Eric Amour</span>
                  <span v-if="item.responsible">{{item.responsible.fullname}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import caisse from '../../../assets/icons/caisse.svg'
import namer from '../../helper/add/nameLabel'

export default {
  name: "Index",
  components: {
    namer,
  },
  props: {
    donnes: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: 'Caisses par défaut'
    }
  },

  data () {
    return {
      icons: {
        caisse,
      },
    }
  },

  methods: {
    goToDetail (item) {
      console.log(item)
    },

    gotoControle () {
      this.$router.push('/controleCaisse')
    },

    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
</style>
