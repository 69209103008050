<template>
  <div class="text-left">
    <div>
      <div class="text-90 text-c18">Caisses liées</div>
      <div class="h-1 bg-d w-full"> </div>
    </div>

    <div class="flex flex-wrap">
      <div class="w-1/3 pr-4" v-for="(item, index) in donnes" :key="index">
        <div class="item bg-white cursor-pointer p-6 mt-6" @click="goToDetail(item)">
          <div class="w-full text-c14 font-c4 text-left">
            <div class="w-full flex items-center">
              <icon
                  :data="icons.caisse"
                  height="35"
                  width="35"
                  class="mr-2 cursor-pointer"
                  original
              />
              <div class="text-c16 w-full">{{ item.person.fullname }}</div>
            </div>
          </div>

          <div class="mt-4 flex">
            <div class="w-3/4 text-black text-c22 font-c5">
              {{ refactorAmount(item.amount, '.') }}
              <span class="text-90">fcfa</span>
            </div>
            <div class="w-1/4 text-c12 text-60">
              <span class="text-green-clear font-c7">+2% </span>cette semaine
            </div>
          </div>

          <div class="flex mt-4 items-center">
            <div class="w-1/2">
              <div class="text-c8 text-60">Responsable actuel :</div>
              <div class="flex items-center mt-2">
                <namer
                    :first="item.person.fullname"
                    :second="item.person.fullname.substr(1, item.person.fullname.length)"
                    height="23px"
                    width="23px"
                    size="9px"
                />
                <div class="ml-2 text-c10">{{ item.person.fullname }}</div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import caisse from '../../../assets/icons/caisseGreen.svg'
import namer from '../../helper/add/nameLabel'

export default {
  name: "Index",
  components: {
    namer,
  },
  props: {
    donnes: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        caisse,
      },
    }
  },

  methods: {
    goToDetail (item){
       console.log(item)
    },
    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
</style>
